import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { fetchStories } from "../../../../redux-store/Actions/story-action";
// import * as yup from "yup";
// import { updateIssuesApi } from "../../../../api/publisher";
import api_config from "../../../../api/api_config";
import { formatDuration } from "../../../../api/publisher";
import { fetchStoryDetailsApi } from "../../../../api/publisher";
import {DateTime} from 'luxon';

function StoryDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const id = params.id;

  const [story, setStoryDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await fetchStoryDetailsApi(id);
        console.log("🚀 ~ fetchData ~ result:", result)
        setStoryDetails(result.data.data);
      } catch (error) {
        console.error("Error fetching story details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="header mt-md-3 mb-5">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/publications" onClick={toPage("/publications/")}>
                      Publications
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a
                      href="/stories"
                      onClick={toPage("/publications/stories")}
                    >
                      Stories
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-active="page">
                    Stories Details
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row">
              <div className="card">
                {isLoading ? (
                  <div className="card-body">
                    <div className="loader-holder d-flex justify-content-center align-items-center">
                      <Loader
                        type="BallTriangle"
                        heigth="100"
                        width="100"
                        color="green"
                        ariaLabel="loading-indicator"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="card-body p-3">
                    {story && (
                      <div>
                        <div className="p-4">
                          <div>
                            <h6 className="header-pretitle">Overview</h6>

                            <h1 className="header-title">
                              Title: {story.title}
                            </h1>
                          </div>

                          <hr />
                        </div>

                        <div className="row px-4">
                          <div className="col-md-6">
                            <audio
                              controlsList="nodownload"
                              controls
                              src={
                                story.file && story.file.includes("https")
                                  ? `${story.file}`
                                  : `${api_config.STORAGE_URL}${story.file}`
                              }
                            >
                              Your browser does not support the
                              <code>audio</code> element.
                            </audio>
                            <p className="audio-file-name">
                              {story.podcast_file_info
                                ? story.podcast_file_info.name
                                : "No audio name"}
                            </p>
                          </div>
                        </div>

                        <div className="px-4">
                          <section className="detail-section">
                            <div className="detail-title">
                              <div className="row">
                                <div className="col">
                                  <h3>Story Information</h3>
                                </div>

                                <div className="col-auto">
                                  <Link
                                    to={`/publications/stories/edit/${story.id}`}
                                    className="btn-link"
                                  >
                                    <i className="fe fe-edit-3"></i>
                                    <span className="ml-2">Edit</span>
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <div className="detail-contents pb-3">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="dc-title">Publisher</div>
                                  <div className="dc-body">
                                    {story.channel?.channelName}
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="dc-title">Title</div>
                                  <div className="dc-body">{story.title}</div>
                                </div>

                                <div className="col-md-4">
                                  <div className="dc-title">Genre</div>
                                  <div className="dc-body">
                                    {story.genre?.name}
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="dc-title">
                                    Published Date and Time
                                  </div>
                                  <div className="dc-body">
                                  {story.releaseDate}
                                    {/* {DateTime.fromMillis(
                                      story.publication_timestamp
                                    )
                                      .setZone(
                                        Intl.DateTimeFormat().resolvedOptions()
                                          .timeZone
                                      )
                                      .toLocaleString(
                                        DateTime.DATETIME_SHORT_WITH_SECONDS
                                      )} */}
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="dc-title">Price</div>
                                  <div className="dc-body">
                                    {story.currency + "" + story.price}
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="dc-title">Language</div>
                                  <div className="dc-body">
                                    {story.language}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="dc-title">Descriptrion</div>
                                  <div className="dc-body">
                                    {story.description}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>

                        <div className="px-4">
                          <section className="detail-section mt-4">
                            <div className="detail-title">
                              <div className="row">
                                <div className="col">
                                  <h3>File Information</h3>
                                </div>
                              </div>
                            </div>

                            {story.podcast_file_info ? (
                              <div className="detail-contents pb-3">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="dc-title">File Name</div>
                                    <div className="dc-body">
                                      {story.podcast_file_info.name}
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="dc-title">File Size</div>
                                    <div className="dc-body">
                                      {story.podcast_file_info.size} MB
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="dc-title">
                                      Audio Duration
                                    </div>
                                    <div className="dc-body">
                                      {formatDuration(
                                        story.podcast_file_info.duration
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              "No file info"
                            )}
                          </section>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default StoryDetail;
